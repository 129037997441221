/* eslint-disable*/
import VueStoreService from './VueStoreService';
const PeriodService = {
    async getPeriodos(filter,store){
        var resp = await VueStoreService.get("period"+filter, store)
        return resp;
    },
    async getActualPeriod(project_id,store){
        var resp = await VueStoreService.get("period/periodName/"+project_id, store)
        return resp;
    },
    async savePeriod(data,store){
        var resp = await VueStoreService.post("period",data,store)
        return resp;
    },
    async saveMasivPeriod(data,store){
      var resp = await VueStoreService.post("period/masive",data,store)
      return resp;
    },
    async getPeriodById(period_id,url,store){
        var resp = await VueStoreService.get( "period/"+period_id+url,store)
        return resp;
    },
    async updatePeriod(period_id,data,store){
        var resp = await VueStoreService.put("period/"+period_id,data,store)
        return resp;
    },

    async deletePeriod(period_id,store){
        var resp = await VueStoreService.delete("period/"+period_id,store)
        return resp;
    },
    async deleteMassive(data,store){
        var resp = await VueStoreService.post("period/status_masive",data,store)
        return resp;
      },
}

export default PeriodService;