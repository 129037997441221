<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <!-- <filters :filtros="fields" :is-add.sync="isAdd" ref="filters"> </filters> -->
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col lg="12" class="d-flex flex-column flex-lg-row justify-content-start">
              <div
                class="w-100 mb-1 mb-lg-0 d-flex justify-content-center justify-content-lg-start"
              >
                <b-form-group label="Proyectos" label-for="project" class="mr-2 w-100">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proyectos"
                    label="code"
                    input-id="project"
                    :reduce="(proyectos) => proyectos.id"
                    placeholder="Proyectos"
                    @input="changeProyect(project_id)"
                    v-model="project_id"
                    class="select-obra"                    
                  >
                    <template v-slot:selected-option="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div
                class="w-100 d-flex align-items-center h-100 justify-content-center justify-content-lg-end mb-1 mb-lg-0 flex-wrap flex-md-nowrap gap-1"
              >
                <b-button
                  class="mr-2 mb-1 mb-md-0"
                  variant="warning"
                  @click="showModal()"
                >
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Generar </span>
                </b-button>
                <b-button variant="success" class="mr-2 mb-1 mb-md-0" @click="showModalWeek()">
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
                </b-button>
                <b-button   v-if="selectedRecords.arrayId.length > 0" class="mr-2 mb-1 mb-md-0" variant="danger" @click="changeStatus()">
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Eliminar </span>
                </b-button>
              </div>
            </b-col>
          </b-row>        
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="visibleFields"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
          ref="selectableTable"
          selectable
          @row-selected="onRowSelectedOrder"
        >
          <!-- Column: Actions -->
          <template #head(row)="">
              <div class="d-flex gap-2">
                <b-form-checkbox
                  id="checkall"
                  :checked="checkAll"
                  @change="selectAll((checkAll = !checkAll))"
                />
              </div>
            </template>
            <template #cell(row)="data">
              <div style="width: 0px !important">
                <b-form-checkbox disabled :checked="selectedRecords.arrayId.includes(data.item)" />
              </div>
            </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class=""
              @click.prevent="edit(data.item)"
              v-b-tooltip.noninteractive.hover.top="'Editar'"
              variant="flat-success"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              v-b-tooltip.noninteractive.hover.top="'Eliminar'"
              @click="deleteAction(data.item)"
              variant="flat-danger"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
          <template #cell(date_start)="data">
            <span>{{ validDate(data.item.date_start) }}</span>
          </template>
          <template #cell(date_end)="data">
            <span>{{ validDate(data.item.date_end) }}</span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-semanas"
        ref="modal-semanas"
        centered
        title="Generación de Semanas"
        ok-only
        hide-footer
        size="lg"
        @hidden="resetFormWeeks()"
      >
        <b-card-text>
          <b-row>
            <b-col sm="8">
              <div class="d-flex w-100">
                <b-form-group label="Fecha Inicio" label-for="date_start" class="mr-1 w-100">
                  <flat-pickr
                    @input="diferenDate(date_start, 1)"
                    id="date_start"
                    v-model="date_start"
                    class="form-control"
                    :config="config"
                  />
                </b-form-group>
                <b-form-group label="Fecha Fin" label-for="date_end" class="w-100">
                  <flat-pickr
                    @input="diferenDate(date_end, 2)"
                    id="date_end"
                    v-model="date_end"
                    class="form-control"
                    :config="config"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="4" class="d-flex align-items-center justify-content-center">
              <b-button class="mr-2 mb-1 mb-md-0" variant="warning" @click="generar()">
                <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Generar </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-table
                class="position-relative"
                :hover="true"
                :items="semanas"
                responsive
                :fields="fields2"
                show-empty
                empty-text="Sin Semanas"
                no-border-collapse
                style="max-height: 300px !important; overflow-y: scroll"             
              >                              
                <template #cell(date_start)="data">
                  <span>{{ validDate(data.item.date_start) }}</span>
                </template>
                <template #cell(date_end)="data">
                  <span>{{ validDate(data.item.date_end) }}</span>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class=""
                    v-b-tooltip.noninteractive.hover.top="'Eliminar'"
                    @click="deleteWeekAction(data.index)"
                    variant="flat-danger"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </template>
                <template #cell(nro)="data">
                  <span>Semana {{ data.index + 1 }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" class="d-flex justify-content-center">
              <b-button class="mr-2 mb-1 mb-md-0" variant="success" @click.prevent="saveWeeks()">
                <span class="text-nowrap"> Guardar </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-text>
      </b-modal>
      <b-modal
        id="addWeek"
        ref="addWeek"
        centered
        :title="isEdit ? 'Editar Semana' : 'Agregar Semana'"
        ok-only
        hide-footer
        size="md"
        @hidden="reset()"
      >
        <b-card-text>
          <validation-observer #default="{ invalid }" ref="refFormObserver">
            <b-form class="p-2" @submit.prevent="saveWeek(items)">
              <b-row>
                <b-col sm="12">
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex w-100">
                      <validation-provider
                        #default="{ errors }"
                        name="items.date_start"
                        rules="requeridoE"
                        class="mr-1"
                      >
                        <b-form-group
                          label="Fecha Inicio"
                          label-for="date_start"
                          class="mr-1 w-100"
                        >
                          <flat-pickr
                            id="date_start"
                            @input="diferenDate2(items.date_start, 1)"
                            v-model="items.date_start"
                            class="form-control"
                            :config="config2"
                          />
                          <small
                            class="text-danger alert"
                            :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                            >{{ errors[0] }}</small
                          >
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        name="items.date_end"
                        rules="requeridoE"
                      >
                        <b-form-group label="Fecha Fin" label-for="date_end" class="w-100">
                          <flat-pickr
                            id="date_end"
                            v-model="items.date_end"
                            class="form-control"
                            :config="config2"
                          />
                          <small
                            class="text-danger alert"
                            :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                            >{{ errors[0] }}</small
                          >
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <validation-provider #default="{ errors }" name="items.name" rules="requeridoE">
                      <b-form-group label="Nombre" label-for="nameWeek" class="w-100">
                        <b-form-textarea
                          id="nameWeek"
                          placeholder="Nombre"
                          rows="2"
                          max-rows="2"
                          no-resize
                          v-model="items.name"
                        ></b-form-textarea>
                        <small
                          class="text-danger alert"
                          :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                          >{{ errors[0] }}</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <div class="d-flex w-100 justify-content-center">
                    <b-button variant="danger" type="submit">
                      <b-spinner v-if="isDisabled" small />
                      <span v-if="isDisabled" class="px-1">guardando...</span>
                      <span v-else>{{ isEdit ? 'Actualizar' : 'Agregar' }}</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
// import filters from './filters.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
// import RestrictionsService from '@/services/RestrictionsService'
import ProjectsService from '@/services/ProjectsService'
import PeriodService from '@/services/PeriodService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import moment from 'moment'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Obtiene la fecha de mañana
    return {
      checkAll: false,
      required,
      showLoading: false,
      periodsArrayEmpty: true,
      show: true,
      isEdit: false,
      status: '',
      config: {
        altInput: false,        
        enableTime: false,
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Sep',
              'Оct',
              'Nov',
              'Dic'
            ],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        },
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ]
      },
      config2: {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Sep',
              'Оct',
              'Nov',
              'Dic'
            ],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        },
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ]
      },
      items: {
        date_start: new Date(),
        date_end: new Date(),
        name: ''
      },
      selectedRecords: {
        arrayId: []
      },
      estados: [
        {
          text: 'Eliminar',
          value: 'Eliminar'
        },       
      ],
      fields: [
      { key: 'row', label: '', sortable: false, visible: true },
        { key: 'project.code', label: 'Id-Proyecto', sortable: false, visible: true },
        { key: 'name', label: 'Semana', sortable: false, visible: true },
        { key: 'date_start', label: 'Fecha Inicio', sortable: false, visible: true },
        { key: 'date_end', label: 'Fecha Fin', sortable: false, visible: true },
        { key: 'actions', label: 'Acciones', visible: true }
      ],
      fields2: [
        { key: 'nro', label: 'Número de Semana', sortable: false, visible: true },
        { key: 'date_start', label: 'Inicio', sortable: false, visible: true },
        { key: 'date_end', label: 'Fin', sortable: false, visible: true },
        { key: 'actions', label: 'Acciones', visible: true }
      ],
      temp_date_start: null,
      temp_date_end: null,
      date_start: tomorrow,
      date_end: tomorrow,
      project_id: JSON.parse(localStorage.getItem('project_id')),
      records: [],
      semanas: [],
      proyectos: [],
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'asc',
      isAdd: false,
      isDisabled: false,
      canGenerated: false
    }
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    AppCollapse,
    AppCollapseItem
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    }
  },
  mounted() {
    // this.getSelect()
    //this.items.date_end = null;
    if(this.items.date_end){
      this.items.date_end.setDate(this.items.date_end.getDate() + 6)
    }
    //this.items.date_end.setDate(this.items.date_end.getDate() + 6)
    this.arrayFilters.push({
      keyContains: 'project_id',
      key: 'equals',
      value: this.project_id
    })
    this.getData()
    this.getSelect()
  },
  methods: {
    async changeStatus() {     
      let filterArrays = []
      for (let index = 0; index < this.selectedRecords.arrayId.length; index++) {
        const element = this.selectedRecords.arrayId[index]
        filterArrays.push(element.id)
      }
      this.$swal({
        title: '¿Seguro que desea cambiar a esta(s) restriccion(es)?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          if (filterArrays.length > 0) {
            console.log({dataEliminted:filterArrays})
            const resp = await PeriodService.deleteMassive(
              {               
                array_id: filterArrays
              },
              this.$store
            )
            if (!resp.error) {
              this.currentPage = 1
              this.$swal({
                icon: 'success',
                title: 'Cambiado!',
                text: 'Los estados fueron cambiados.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
              this.getData()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: 'Ocurrió un error al cambiar el estado de la restricción seleccionada.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            }
          }
        }
      })
    },
    onRowSelectedOrder(items) {
      console.log('items', items)
      this.selectedRecords.arrayId = items
      console.log('this.selectedRecords.arrayId', this.selectedRecords.arrayId)
    },
    selectAll(val) {
      console.log('val', val)
      if (val) {
        console.log('this.$refs.selectableTable', this.$refs.selectableTable)
        this.$refs.selectableTable.selectAllRows()
      } else {
        this.$refs.selectableTable.clearSelected()
      }
      //
    },
    validDate(fecha) {
      let fecha2 = new Date(fecha)
      
      if (fecha2 != null) {
        const year = new Date(fecha2).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD')
      }
      return ''
    },
    showFilters() {
      this.isAdd = true
    },
    showModal() {
      if (this.project_id != null) {
        this.$refs['modal-semanas'].show()
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Proyecto!',
          text: 'Seleccione un proyecto para generar o agregar semanas.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    showModalWeek() {
      if (this.project_id != null) {
        this.isEdit = false
        this.$refs['addWeek'].show()
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Proyecto!',
          text: 'Seleccione un proyecto para generar o agregar semanas.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    generar() {
      let date1T = moment(this.date_start).format('yyyy-MM-DD')
      let date2T = moment(this.date_end).format('yyyy-MM-DD')
      var weeks = []
      var weekCont = 1
      var start = moment(this.date_start).format('yyyy-MM-DD')
      var end = ''
      if (date1T === date2T) {
        weeks.push({
          name: 'Semana ' + (this.records.length + weekCont),
          date_start: date1T,
          date_end: date2T,
          project_id: this.project_id
        })
      } else {
        for (
          var d = new Date(this.date_start);
          d <= new Date(this.date_end);
          d.setDate(d.getDate() + 1)
        ) {
          let date2T = moment(d).format('yyyy-MM-DD')
          let date1 = moment(start)
          let date2 = moment(date2T)
          if (date2.diff(date1, 'days') == 6) {
            weeks.push({
              name: 'Semana ' + (this.totalElements + weekCont),
              date_start: moment(start).format('yyyy-MM-DD'),
              date_end: moment(date2T).format('yyyy-MM-DD'),
              project_id: this.project_id
            })
            start = moment(date2T).add(1, 'days')
            weekCont++
          }
          end = d
        }
        weeks.push({
          name: 'Semana ' + (this.totalElements + weekCont),
          date_start: moment(start).format('yyyy-MM-DD'),
          date_end: moment(end).format('yyyy-MM-DD'),
          project_id: this.project_id
        })
      }
      console.log('weeks', weeks)

      this.semanas = weeks
    },
    diferenDate(fecha, val) {
      if (fecha != null) {
        if (val == 1) {
          let date1T = moment(fecha).format('yyyy-MM-DD')
          let date2T = moment(this.date_end).format('yyyy-MM-DD')
          let date1 = moment(date1T)
          let date2 = moment(date2T)
          if (date1.diff(date2, 'days') > 0) {
            this.$swal({
              icon: 'warning',
              title: 'Fecha inválida',
              text: 'La fecha inicio no puede ser mayor a la fecha fin',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            this.date_start = new Date()
          }
        } else {
          let date1T = moment(this.date_start).format('yyyy-MM-DD')
          let date2T = moment(fecha).format('yyyy-MM-DD')
          let date1 = moment(date1T)
          let date2 = moment(date2T)
          if (date2.diff(date1, 'days') < 0) {
            this.$swal({
              icon: 'warning',
              title: 'Fecha inválida',
              text: 'La fecha fin no puede ser menor a la fecha inicio',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            this.date_end = new Date()
          }
        }
      }
    },
    diferenDate2(fecha, val) {
      if (fecha != null) {
        if (val == 1) {
          const fechaValid = new Date(fecha)
          fechaValid.setDate(fechaValid.getDate() + 7)
          this.items.date_end = fechaValid
        }
      }
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    changeProyect(id) {
      this.arrayFilters[0].value = id
      console.log('this.arrayFilters', this.arrayFilters)
      this.getData()
    },
    async getSelect() {
      const url = `?limit=100&`
      const respProyect = await ProjectsService.getProyectos(url, this.$store)
      console.log('respProyect', respProyect)
      if (respProyect.status) {
        this.proyectos = respProyect.data.rows
        // if (this.proyectos.length > 0) {
        //   this.arrayFilters.push({
        //     keyContains: 'project_id',
        //     key: 'equals',
        //     value: respProyect.data.rows[0].id
        //   })
        //   this.project_id = respProyect.data.rows[0].id
        //   this.getData()
        // }
      }
    },
    async getData() {
      this.showLoading = true
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters)
      const resp = await PeriodService.getPeriodos(url, this.$store)
      console.log('resp', resp)
      if (resp.status) {
        console.log('resp.data.rows', resp.data.rows)
        if (resp.data.rows.length > 0) {
          this.canGenerated = true
          this.config.minDate = this.date_start
        }
        else{
          this.canGenerated = false
          this.config.minDate = null
        }
        this.records = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }

      this.showLoading = false
    },
    deleteWeekAction(data) {
      this.$swal({
        title: '¿Desea eliminar esta semana?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteWeek(data)
        }
      })
    },
    deleteWeek(data) {
      const filter = this.semanas.filter((_, index) => index !== data)
      this.semanas = filter
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar este periodo?',
        text: 'Recuerda que esto podria generar periodos incongruentes',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      console.log('id', id)
      const resp = await PeriodService.deletePeriod(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El periodo ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar el periodo seleccionado. Error: '+resp.data.message,
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    async edit(item) {
      this.isEdit = true
      this.items.date_start = this.validDate(item.date_start)
      this.items.date_end = this.validDate(item.date_end)
      this.items.name = item.name
      this.items.id = item.id
      this.project_id = item.project.id
      this.temp_date_start = item.date_start
      this.temp_date_end = item.date_end
      this.$refs['addWeek'].show()
    },
    async saveWeeks() {
      // const existWeeks = await PeriodService.getPeriodById(this.r)
      if (this.semanas.length > 0) {
        this.$swal({
          title: '¿Esta seguro de generar estas semanas? Se eliminara el progreso de sus semanas.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, generar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async (result) => {
          if (result.value) {
            this.isDisabled = true
            for (let index = 0; index < this.semanas.length; index++) {
              const element = this.semanas[index]
              element.date_start = element.date_start.concat(' ', '00:00:00')
              element.date_end = element.date_end.concat(' ', '23:59:59')
              this.semanas[index].date_start = moment(element.date_start).subtract(5, 'hours')
              this.semanas[index].date_end = moment(element.date_end).subtract(5, 'hours')
            }
            console.log('this.semanas', this.semanas)
            const resp = await PeriodService.saveMasivPeriod(this.semanas, this.$store)
            if (resp.status) {
              this.$swal({
                icon: 'success',
                title: 'Generado!',
                text: 'Semana generadas con exito.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
              this.$refs['modal-semanas'].hide()
              this.getData()
            } else {
              this.$swal({
                title: 'Error!',
                text: ' Hubo un error al registrar la semana',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
            this.isDisabled = false
          }
        })
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Genere Semanas!',
          text: 'Debe generar algunas semanas primero.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    reset() {
      this.isEdit = false
      const resetDate = new Date()
      resetDate.setDate(resetDate.getDate() + 6)
      // this.project_id = null
      this.temp_date_end = null
      this.temp_date_start = null
      this.items = {
        date_start: new Date(),
        date_end: resetDate,
        name: ''
      }
    },
    resetFormWeeks() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.date_start = tomorrow
      this.date_end = tomorrow
      this.semanas = []
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.isEdit = false
      this.items = {
        date_start: new Date(),
        date_end: new Date(),
        name: ''
      }
    },
    async saveWeek(data) {
      console.log('data', data)
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          this.isDisabled = true
          let resp = ''
          let datos = {}
          data.date_start = data.date_start.concat(' ','00:00:00')
          data.date_end = data.date_end.concat(' ','23:59:59')
          datos.date_start = moment(data.date_start).subtract(5,'hours')
          datos.date_end = moment(data.date_end).subtract(5,'hours')
          datos.name = data.name
          // datos.date_end = new Date(data.date_end)
          datos.project_id = this.project_id
          console.log({datosSending:datos});
          if (this.isEdit == false) {
            resp = await PeriodService.savePeriod(datos, this.$store)
          } else {
            let id = data.id
            resp = await PeriodService.updatePeriod(id, datos, this.$store)
          }
          console.log('resp', resp)
          if (resp.status) {
            this.$swal({
              title: this.isEdit == true ? 'Actualizado' : 'Registrado',
              text: 'La semana ha sido ' + (this.isEdit == true ? 'actualizado.' : 'registrado.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.$refs['addWeek'].hide()
            this.getData()
            this.resetForm()
          } else {
            this.$swal({
              title: 'Error!',
              text:
                ' Hubo un error al ' +
                (this.isEdit == true ? 'actualizar' : 'registrar') +
                ' la semana. Error : ' + resp.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
          this.isDisabled = false
        } else {
          console.log('no validado')
        }
      })
    }
  }
}
</script>

<style lang="scss" s>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-thumb {
  background: linear-gradient(118deg, #001955, rgba(52, 53, 71, 0.7));
  border-radius: 10px;
}
.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}
</style>